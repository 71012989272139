<template>
  <NuxtLayout name="search-basic-layout">
    <div class="overflow-y-hidden">
      <SearchBarWrapper></SearchBarWrapper>
      <SpaceBannerWrapper></SpaceBannerWrapper>
      <div class="h-[40px]"></div>
      <SpaceTypeListWrapper></SpaceTypeListWrapper>
      <LocationSearchWrapper class="mt-[50px] mb-[40px] mobile:mt-[40px]"></LocationSearchWrapper>
      <hr class="mx-auto pc:w-[calc(1366px-140px)]">
      <UsageSearchWrapper class="mt-[50px] mb-[40px]"></UsageSearchWrapper>
      <hr class="mx-auto pc:w-[calc(1366px-140px)]">
      <SpaceListDisplayWrapper class="my-[50px]"
                               :title="$t('sub_title.new_space')"
                               :sub-title="$t('sub_title.new_space_description')"
                               :iconName="'new'"
                               :space-card-list="newSpaceList"
                               :is-more="true"
                               :more-search-param="newSpaceListQueryData"></SpaceListDisplayWrapper>
      <SpaceListDisplayWrapper class="my-[50px]"
                               :title="$t('sub_title.trend_space')"
                               :sub-title="$t('sub_title.trend_space_description')"
                               :iconName="'hot'"
                               :space-card-list="trendSpaceList"></SpaceListDisplayWrapper>
      <OurFeaturesWrapper></OurFeaturesWrapper>
      <HowToReserveWrapper></HowToReserveWrapper>
      <FooterLoginWrapper></FooterLoginWrapper>
    </div>
  </NuxtLayout>
</template>
<script setup>


import SearchBarWrapper from "@/components/SearchIndex/SearchBarWrapper/SearchBarWrapper.vue";
import FooterLoginWrapper from "@/components/SearchIndex/FooterLogin/FooterLoginWrapper.vue"
import LocationSearchWrapper from "@/components/SearchIndex/FastSearch/LocationSearchWrapper.vue"
import UsageSearchWrapper from "@/components/SearchIndex/FastSearch/UsageSearchWrapper.vue"
import SpaceListDisplayWrapper from "@/components/SearchIndex/SearchSpaceCard/SpaceListDisplayWrapper.vue"
import OurFeaturesWrapper from "../components/SearchIndex/OurFeatures/OurFeaturesWrapper.vue";
import HowToReserveWrapper from "../components/SearchIndex/HowToReserve/HowToReserveWrapper.vue";
import SpaceTypeListWrapper from "@/components/SearchIndex/SpaceTypeList/SpaceTypeListWrapper.vue";
import SpaceBannerWrapper from "@/components/SearchIndex/SpaceBanner/SpaceBannerWrapper.vue";

import {getElementByDate} from "@/utils/parseTool.js"
import {useSettingStore} from "@/stores/setting";
import {newSpaceListQueryData, trendSpaceListQueryDataList} from "@/config/testCode.js"
import {useSearch} from "@/composables/useSearch";
import {useGoTo} from "@/composables/useGoTo";
const picUrl = import.meta.env.VITE_APP_BASE_IMG_URL

const settingStore = useSettingStore()
const {handleSearch} = useSearch()
const route = useRoute();
const {go} = useGoTo()

// 使用 useAsyncData 來抓取 newSpaceListQueryData 資料
const {data: newSpaceListData} = await useAsyncData(
    'newSpaceListQueryData',
    () => handleSearch(newSpaceListQueryData));

const newSpaceList = computed(() => {
  return newSpaceListData.value ? newSpaceListData.value.list.slice(0, 4) : [];
});

// 使用 useAsyncData 來抓取 trendSpaceListQueryDataList 資料
const {data: trendSpaceListData} = await useAsyncData(
    'trendSpaceListQueryDataList',
    () => handleSearch(getElementByDate(trendSpaceListQueryDataList)));

const trendSpaceList = computed(() => {
  return trendSpaceListData.value ? trendSpaceListData.value.list : [];
});

// 如果資料非響應式，可以使用 useServerSeoMeta()，在 server 端預先處理完 meta 相關邏輯，提升網頁效能
const seoMeta = {
  title: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  description:'空間便利店是一個線上的場地租借平台。我們提供線上預約，省去聯絡，方便又順利。活動空間、會議室，攝影棚、餐廳包廂、聚會場地都可預約',
  keywords:'場地租借、會議室、攝影棚、廚房租借、活動空間、餐廳包廂、聚會場地，都可線上預約',
  ogTitle:'空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  ogSiteName: '空間便利店',
  ogDescription: '空間便利店是一個線上的場地租借平台！我們提供線上預約，省去聯絡，方便又順利。活動空間、會議室，攝影棚、餐廳包廂、聚會場地都可預約',
  ogImage: 'https://pic.onestep.place/images/onestep-og.png',
  ogImageAlt: '空間便利店｜方便順利的場地租借平台。時租日租場地，活動聚會場地，商務會議線上預約',
  ogUrl: 'https://www.onestep.place',
  ogType: 'website',
};

useSeoMeta(seoMeta);

// todo 目前測試動態met資料，正式首頁要改成正常資料
/*useAsyncData('seoMeta', async () => {
  try {
    const res = await handleSearch(newSpaceListQueryData);
    if (res && res.list && res.list.length > 1) {
      const item = res.list[1];
      const seoMeta = {
        title: 'onestep 空間便利店 搜尋首頁',
        ogTitle: item.brand_name,
        ogSiteName: 'onestep 空間便利店',
        description: item.city,
        ogDescription: item.city,
        ogImage: picUrl + item.photo_url
      };

      if (process.client) {
        seoMeta.ogUrl = window.location.href;
      }

      // 如果資料非響應式，可以使用 useServerSeoMeta()，在 server 端預先處理完 meta 相關邏輯，提升網頁效能
      useSeoMeta(seoMeta);
    } else {
      console.error('資料格式不符合預期');
    }
  } catch (error) {
    console.error('useAsyncData seoMeta error', error);
  }
});*/


onMounted(() => {
  settingStore.updateHomeRouter('/')
  /*if (route.query.sid) {
    go(`/space/${route.query.sid}`)
  }*/
})


</script>
<style scoped
       lang="scss">

</style>
